// 实名认证
import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

// 人工审核认证列表
export function getManualAuditList(data = {}, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/userCertificateInfo/manualAuditList',
    method: 'post',
    data
  })
}

// 获取待审核数
export function getUnApproveTotal(data = {}, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/userCertificateInfo/unApproveTotal',
    method: 'post',
    data
  })
}

// 提交审核
export function approveCommit(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/userCertificateInfo/approveCommit',
    method: 'post',
    data
  })
}

// 审核
export function getApproveDetail(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/userCertificateInfo/approve',
    method: 'post',
    data
  })
}

