<template>
  <!-- 审核 -->
  <div class="valid-pass-form">
    <div class="radio-group_wrapper">
      <el-radio-group v-model="data.isCertificated">
        <el-radio :label="REAL_NAME_PASS">审核成功</el-radio>
        <el-radio :label="REAL_NAME_NOT_DEAL_OR_FAIL">审核失败</el-radio>
      </el-radio-group>
      <LoadingBtn
        class="success-btn"
        type="primary"
        @click="doSubmit"
        v-p="['externaladmin:user:list:approveCommit']"
        v-if="option.showSubmitBtn0"
        >确定</LoadingBtn
      >
    </div>

    <div class="fail-message" v-if="option.showFailMsg">
      <el-form ref="form" :model="data" :rules="rules">
        <el-form-item label="失败原因" prop="manualApproveOpinion">
          <el-input
            :disabled="option.manualApproveOpinionDisable"
            style="max-width: 300px"
            type="textarea"
            placeholder="请输入失败原因"
            resize="none"
            :rows="2"
            v-model="data.manualApproveOpinion"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="option.showFailMsgBtnWrapper">
          <LoadingBtn
            type="primary"
            v-if="option.showSubmitBtn1"
            @click="doSubmit"
            v-p="['externaladmin:user:list:approveCommit']"
            >确定</LoadingBtn
          >
          <LoadingBtn @click="doCancel">取消</LoadingBtn>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  REAL_NAME_NOT_DEAL_OR_FAIL, //未实名认证(审核失败) isCertificated
  REAL_NAME_PASS, //实名认证通过 isCertificated
  REAL_NAME_STATUS,
  APPROVE_DEAL, //已处理
  NO_REAL_NAME, //未进行实名认证
  REAL_NAME_FAIL, //实名认证失败
  REAL_NAME_SUCCSS //实名认证成功
} from '@/utils/constant'
import { approveCommit } from '@/api/auth/realNameAuthApi'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      REAL_NAME_NOT_DEAL_OR_FAIL,
      REAL_NAME_PASS,
      originData: {},
      rules: {
        manualApproveOpinion: [
          {
            required: true,
            message: '失败原因必填'
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters(['name']),

    option({ originData, data }) {
      const { isCertificated } = data
      const status = REAL_NAME_STATUS(originData)
      //isPass 成功， isFail 失败
      const [isPass, isFail] = [isCertificated == REAL_NAME_PASS, isCertificated == REAL_NAME_NOT_DEAL_OR_FAIL]
      const list = {
        [NO_REAL_NAME]: {
          showSubmitBtn0: true,
          showFailMsgBtnWrapper: false,
          showFailMsg: isFail
        },
        [REAL_NAME_FAIL]: {
          showSubmitBtn0: isPass,
          showFailMsgBtnWrapper: true,
          showFailMsg: isFail,
          showSubmitBtn1: isPass,
          manualApproveOpinionDisable: isFail
        },
        [REAL_NAME_SUCCSS]: {
          showSubmitBtn0: false,
          showFailMsgBtnWrapper: true,
          showFailMsg: isFail,
          showSubmitBtn1: isFail
        }
      }
      return list[status]
    }
  },

  watch: {
    data: {
      handler() {
        //保存原始数据
        this.originData = cloneDeep(this.data)
      },
      immediate: true
    }
  },

  methods: {
    /**
     * @description: 提交审核
     * @param {*}
     * @return {*}
     */
    async doSubmit() {
      const { manualApproveOpinion, id, isCertificated } = this.data
      if (!this.validHandler()) return
      const approveUserName = this.name
      const params = {
        id,
        isCertificated,
        manualApproveStatus: APPROVE_DEAL,
        approveUserName
      }
      if (this.option.showFailMsg) {
        params.manualApproveOpinion = manualApproveOpinion
      }
      try {
        const res = await approveCommit(params)
        if ($SUC(res)) {
          this.$message.success('操作成功')
          this.$emit('approveCommitSuccess')
        }
      } catch {}
    },

    /**
     * @description: 校验表单，当是审核失败时候，失败理由必填
     * @param {*}
     * @return {*}
     */
    async validHandler() {
      const { form } = this.$refs
      if (form) {
        try {
          return await form.validate()
        } catch {
          return false
        }
      }
      return true
    },

    doCancel() {
      this.$emit('doCancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.valid-pass-form {
  display: flex;
  margin-top: 10px;
  width: 100%;
}
.success-btn {
  display: block;
  margin-top: 40px;
}
.el-radio {
  display: block;
  ::v-deep {
    .el-radio__label {
      font-weight: 400;
      color: #606266;
    }
  }
}
.el-radio + .el-radio {
  margin-top: 10px;
}

.fail-message {
  position: relative;
  top: 15px;
  flex: 1;
  display: block;
  ::v-deep {
    .el-form-item__label {
      font-weight: 400;
      color: #606266;
    }
    .el-form-item__error {
      left: 75px;
    }
  }
}
</style>
